<template>
  <!-- 서브컨텐츠 -->
  <div id="container" class="p01 p01_3">

    <!-- 비주얼 -->
    <section class="sub_v">
      <h2>경영이념</h2>
    </section>
    <!-- //비주얼 -->

    <!-- 탭 -->
    <div class="sub_tab">
      <ul>
        <li><a @click="$router.push({ name : 'Introduction01' })">개요</a></li>
        <li class="on"><a>경영이념</a></li>
        <li><a @click="$router.push({ name : 'Introduction03' })">진행사업</a></li>
      </ul>
    </div>
    <!-- //탭 -->

    <section class="con">

      <div class="wrap">
        <img class="logo" src="/images/intro_logo.png" alt="" />
        <ul>
          <li>
            <div>신속</div>
            <p>빠르게 <br />운송요청 수행</p>
          </li>
          <li>
            <div>정확</div>
            <p>고객사의 니즈를 <br />최대한 반영</p>
          </li>
          <li>
            <div>비용</div>
            <p>확실히 절감된 <br />합리적인 비용</p>
          </li>
          <li>
            <div>상생</div>
            <p>운송사와 화물차주 <br />모두의 만족을 위한 노력</p>
          </li>
          <li>
            <div>혁신</div>
            <p>구시대적 관행 <br />타파</p>
          </li>
        </ul>
      </div>

    </section>

  </div>
  <!-- //서브컨텐츠 -->
</template>

<script>
export default {
  name: "Introduction02",
  mounted() {
    document.title = '경영이념 :: 마국로지스'
  },
}
</script>

<style scoped>

</style>