<template>
  <!-- 푸터 -->
  <footer v-if="siteInfo">
    <div class="wrap" :style="{ background: 'url(' + $IMAGE_DOMAIN + siteInfo.site_bottom_logo + ') no-repeat right top'}">
      <h1>{{ siteInfo.company_name }}</h1>
      <div class="info">
        <span>주소 : {{ siteInfo.company_address }}</span>
        <span>전화 : {{ siteInfo.customer_tel }}</span>
        <span>대표 : {{ siteInfo.company_ceo }}</span>
        <span>사업자번호 : {{ siteInfo.business_number }}</span>
        <span>이메일 : {{ siteInfo.company_email }}</span>
      </div>
      <cite>COPYRIGHT &copy; MAGUK LOGIS. ALL RIGHTS RESERVED.</cite>
    </div>
  </footer>
  <!-- //푸터 -->
</template>

<script>
export default {
  name: "MGBottomFooter",
  props: ['siteInfo'],
}
</script>

<style scoped>

</style>