<template>
  <!-- 서브컨텐츠 -->
  <div id="container" class="p04">

    <!-- 비주얼 -->
    <section class="sub_v">
      <h2>실시간 문의</h2>
    </section>
    <!-- //비주얼 -->

    <section class="con">
      <div class="lock">
        <h3>비밀글 기능으로 보호된 글입니다.</h3>
        <h4>작성자와 관리자만 열람하실 수 있습니다. <br/>본인이라면 비밀번호를 입력하세요.</h4>
        <div class="pass">
          <input class="mr-5" type="password" v-model="password" placeholder="비밀번호 입력" @keyup.enter="confirm()">
          <a @click="confirm()">확인</a>
        </div>
        <a class="btn_back" @click="cancel()">이전 페이지로</a>
      </div>
    </section>
  </div>
  <!-- //서브컨텐츠 -->
</template>

<script>
export default {
  name: "QnaPasswordConfirm",
  data() {
    return {
      id: '',
      password: '',
    }
  },
  mounted() {
    document.title = '실시간 문의 :: 마국로지스'
    this.scrollTop()

    this.id = this.$route.query.id
  },
  methods: {
    confirm() {  // 확인
      if (this.password === '') {
        this.notify('error', '비밀번호를 입력해주세요')
        return
      }

      this.$router.push({name: 'QnaDetail', query: {id: this.id, password: this.password}})
    },
    cancel() { // 돌아가기
      this.$router.back()
    }
  }
}
</script>

<style scoped>

</style>