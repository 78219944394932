<template>
  <!-- 서브컨텐츠 -->
  <div id="container" class="p02">

    <!-- 비주얼 -->
    <section class="sub_v">
      <h2>주요거래처</h2>
    </section>
    <!-- //비주얼 -->

    <section class="con">
<!--      <img src="/images/partner01.png" alt="거래처">-->

            <ul>
              <li v-for="(partner, index) in partnerList" :key="index">
                <img :src="$IMAGE_DOMAIN + partner.path" alt="거래처로고"/>
              </li>
            </ul>
    </section>

  </div>
  <!-- //서브컨텐츠 -->
</template>

<script>
export default {
  name: "BusinessPartner",
  data(){
    return {
      partnerList: [],
    }
  },
  mounted() {
    document.title = '주요거래처 :: 마국로지스'

    this.getPartnerList()
  },
  methods: {
    getPartnerList() {
      this.$get(this.$PARTNER, this.$options.name + '_get_partner_list', (result) => {
        this.partnerList = result.data

      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style scoped>

</style>