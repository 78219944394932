<template>
  <!-- 서브컨텐츠 -->
  <div id="container" class="p04">

    <!-- 비주얼 -->
    <section class="sub_v">
      <h2>실시간 문의</h2>
    </section>
    <!-- //비주얼 -->

    <!-- 탭 -->
    <div class="sub_tab">
      <ul>
        <li><a @click="$router.push({ name : 'QnaRegister' })">실시간 문의하기</a></li>
        <li class="on" @click="reloadPage()"><a>문의내역</a></li>
      </ul>
    </div>
    <!-- //탭 -->

    <section class="con">
      <div class="search">
        <input type="text" placeholder="제목 또는 작성자명 입력" v-model="searchWord" maxlength="50" @keyup.enter="search()"/>
        <a @click="search()">검색</a>
      </div>

      <div class="list">
        <div v-if="loaded && qnaList.length === 0">
          <p>#문의 내역이 없습니다.</p>
        </div>

        <table v-else>
          <thead>
          <tr>
            <th class="num">번호</th>
            <th class="tt">제목</th>
            <th class="writer">작성자</th>
            <th class="date">작성일</th>
          </tr>
          </thead>
          <tbody>
          <!-- 반복 -->
          <tr v-for="(qna, index) in qnaList" :key="index" @click="goConfirmPassword(qna.id)">
            <td class="num">{{ totalSize - ((currentPage - 1) * size) - index }}</td>
            <td class="tt"><a><span v-if="qna.state === 2">답변완료</span>{{ qna.title }}</a></td>
            <td class="writer">{{ qna.name }}</td>
            <td class="date">{{ qna.created_at }}</td>
          </tr>
          <!-- //반복 -->
          </tbody>
        </table>
      </div>
      <div class="paging" v-if="pages.length > 0">
        <ul>
          <li><a class="arr prev" @click="goPrev()">이전페이지</a></li>
          <!-- 반복 -->
          <li v-for="(page, index) in pages" :key="index">
            <a :class="{ 'on' : currentPage === page }" @click="getQnaList(page)">{{ page }}</a>
          </li>
          <!-- //반복 -->
          <li><a class="arr next" @click="goNext()">다음페이지</a></li>
        </ul>
      </div>

    </section>

  </div>
  <!-- //서브컨텐츠 -->
</template>

<script>
export default {
  name: "QnaList",
  data() {
    return {
      loaded: false,
      searchWord: '',
      qnaList: [],
      currentPage: 1,
      size: 10, // 한 페이지에 보여줄 size
      totalSize: 0, // 총 데이터 list length
      totalPage: 0,
      pages: [],
      ablePrev: false,
      ableNext: false,
    }
  },
  mounted() {
    document.title = '실시간 문의 :: 마국로지스'

    if (this.$route.query.searchWord) {
      this.searchWord = this.$route.query.searchWord
    }

    this.getQnaList(1)
  },
  methods: {
    reloadPage() {
      this.searchWord = ''
      this.search()
    },
    getQnaList(_page) { // 1:1문의 리스트 가져오기
      this.loaded = false

      let queryString = '?page=' + _page + '&perPage=' + this.size

      if (this.searchWord !== '') {
        queryString = queryString + '&searchWord=' + this.searchWord
      }

      this.$get(this.$QNA_LIST + queryString, this.$options.name + '_get_qna_list', (result) => {
        this.size = result.data.per_page
        this.totalSize = result.data.total
        this.qnaList = result.data.data

        this.currentPage = _page
        this.pagination()

        this.loaded = true
        this.scrollTop()

      }, (result) => {
        this.httpError(result)
      })
    },
    goPrev() { /*이전*/
      if (this.ablePrev) {
        this.getQnaList(this.currentPage - 1)
      }
    },
    goNext() { /*다음*/
      if (this.ableNext) {
        this.getQnaList(this.currentPage + 1)
      }
    },
    pagination() {
      if (this.totalSize > this.size * this.currentPage) {
        this.ableNext = true
      } else {
        this.ableNext = false
      }
      this.ablePrev = (this.currentPage === 1 ? false : true)

      this.totalPage = Math.floor(this.totalSize % this.size === 0 ? this.totalSize / this.size : this.totalSize / this.size + 1)
      this.pages = []

      let startPage = (this.currentPage - 2) > 1 ? this.currentPage - 2 : 1 // 현재페이지 -2 ==> 1보다 크면, page-2 아니면 1
      let endPage = (this.currentPage + 2) > this.totalPage ? this.totalPage : this.currentPage + 2 // 현재페이지 +2 ==> 토탈보다 크면, 토탈 아니면 page+2

      if ((this.currentPage === 1 || this.currentPage === 2) && this.totalPage >= 5) { // 현재페이지 1 or 2이고, 토탈 5랑 같거나 크면 5
        endPage = 5
      } else if (this.currentPage === 1 && this.totalPage === 4) { // 현재페이지 1, 토탈 4
        endPage = 4
      }

      if (this.currentPage === this.totalPage) {
        if ((this.currentPage - 4) > 1) {
          startPage = this.currentPage - 4
        } else {
          startPage = 1
        }
      } else if (this.currentPage === (this.totalPage - 1)) {
        if ((this.currentPage - 3) > 1) {
          startPage = this.currentPage - 3
        } else {
          startPage = 1
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        this.pages.push(i)
      }
    },
    search() {
      if (this.searchWord === '') {
        this.$router.replace({name: 'QnaList'})

      } else {
        this.$router.replace({name: 'QnaList', query: {searchWord: this.searchWord}})
      }

      this.getQnaList(1)
    },
    goConfirmPassword(_id) {  // 문의 비밀번호 확인
      this.$router.push({name: 'QnaPasswordConfirm', query: {id: _id}})
    },
  }
}
</script>

<style scoped>

</style>