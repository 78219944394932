import {createRouter, createWebHashHistory} from "vue-router"

import Main from "@/components/main/Main";
import Introduction01 from "@/components/introduction/Introduction01";
import BusinessPartner from "@/components/businessPartner/BusinessPartner";
import ManagerInformation from "@/components/managerInfo/ManagerInformation";
import QnaList from "@/components/qna/QnaList";
import QnaDetail from "@/components/qna/QnaDetail";
import QnaRegister from "@/components/qna/QnaRegister";
import QnaPasswordConfirm from "@/components/qna/QnaPasswordConfirm";
import Introduction02 from "@/components/introduction/Introduction02";
import Introduction03 from "@/components/introduction/Introduction03";

export default createRouter({
    history: createWebHashHistory(),//2.x대를 설치하시면 작동을 안합니다.
    scrollBehavior (){
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'Main',
            component: Main,
        },
        {
            path: '/introduction/01',
            name: 'Introduction01',
            component: Introduction01,
        },
        {
            path: '/introduction/02',
            name: 'Introduction02',
            component: Introduction02,
        },
        {
            path: '/introduction/03',
            name: 'Introduction03',
            component: Introduction03,
        },
        {
            path: '/business/partner',
            name: 'BusinessPartner',
            component: BusinessPartner,
        },
        {
            path: '/manager/information',
            name: 'ManagerInformation',
            component: ManagerInformation,
        },
        {
            path: '/qna/list',
            name: 'QnaList',
            component: QnaList,
        },
        {
            path: '/qna/detail',
            name: 'QnaDetail',
            component: QnaDetail,
        },
        {
            path: '/qna/register',
            name: 'QnaRegister',
            component: QnaRegister,
        },
        {
            path: '/qna/password/confirm',
            name: 'QnaPasswordConfirm',
            component: QnaPasswordConfirm,
        },
    ],
})
