<template>
  <!-- 서브컨텐츠 -->
  <div id="container" class="p04">

    <!-- 비주얼 -->
    <section class="sub_v">
      <h2>실시간 문의</h2>
    </section>
    <!-- //비주얼 -->

    <!-- 탭 -->
    <div class="sub_tab">
      <ul>
        <li class="on" @click="dataClear()"><a>실시간 문의하기</a></li>
        <li><a @click="$router.push({ name : 'QnaList' })">문의내역</a></li>
      </ul>
    </div>
    <!-- //탭 -->

    <section class="con">

      <div class="form">
        <p>마국로지스는 예비 거래처와의 1:1 친절상담을 원칙으로 하며, 전문적이고 상세한 상담을 통해 만족도를 높이는데 책임을 다하겠습니다.</p>

        <div class="wrap">
          <dl>
            <dt>제목 <em>*</em></dt>
            <dd>
              <input class="w-100" type="text" v-model="title" maxlength="50"/>
            </dd>
          </dl>
          <dl>
            <dt>이름 <em>*</em></dt>
            <dd>
              <input type="text" v-model="name" maxlength="30"/>
            </dd>
          </dl>
          <dl>
            <dt>연락처 <em>*</em></dt>
            <dd>
              <input type="text" placeholder="숫자만 입력" inputmode="numeric" v-model="phone" maxlength="20"/>
            </dd>
          </dl>
          <dl>
            <dt>이메일 <em>*</em></dt>
            <dd>
              <input type="text" v-model="email" maxlength="50"/>
            </dd>
          </dl>
          <dl>
            <dt>문의 비밀번호 <em>*</em></dt>
            <dd>
              <input type="password" v-model="password" maxlength="20"/>
            </dd>
          </dl>
          <dl>
            <dt>유선상담 가능 날짜 <em>*</em></dt>
            <dd>
              <date-picker
                  ref="datePicker"
                  class="date-picker"
                  v-model="selectedDate"
                  :lower-limit="today"
                  :locale="locale"
                  @closed="closedPicker()"
              ></date-picker>
            </dd>
          </dl>
          <dl>
            <dt>유선상담 가능 시간</dt>
            <dd>
              <select class="outline-none" ref="select" v-model="selectedTime">
                <option v-for="(time, index) in timeList"
                        :value="time.time"
                        :key="index">
                  {{ time.name }}
                </option>
              </select>
            </dd>
          </dl>
          <dl>
            <dt>희망톤수 선택 <em>*</em></dt>
            <dd>
              <select v-model="selectedTon">
                <option value="5">5톤</option>
                <option value="14">14톤</option>
                <option value="25">25톤</option>
                <option value="0">기타</option>
              </select>
            </dd>
          </dl>
          <dl>
            <dt>간략 문의내용 <em>*</em></dt>
            <dd>
              <quill-editor id="editor" class="mb-2" theme="snow"
                            :toolbar="['image']" ref="quillEditor"/>
            </dd>
          </dl>
        </div>
        <div class="privacy">
          <input type="checkbox" id="chk_prvc" v-model="chkTerms" @click="chkTerms = !chkTerms"/>
          <label class="pl-5" for="chk_prvc">개인정보 수집 및 이용에 동의합니다.</label><a class="btn_prvc"
                                                                                          @click="openTerms()">내용보기</a>
        </div>
        <div class="btns">
          <a class="red" @click="register()">등록</a>
        </div>
      </div>

    </section>

    <!-- 팝업 -->
    <div id="mask" @click="closeTerms()"></div>
    <div id="pop1" class="pop_layer">
      <strong>개인정보 수집 및 이용에 대한 안내</strong>
      1. 수집/이용 목적 : 상담,예약,문의 등 고객요청 처리 및 결과 회신<br />
      2. 수집하는 항목 : 이름,연락처,주소,이메일<br />
      3. 보유 / 이용 기간 : 고객요청 처리 후 3개월<br />
      4. 동의를 거부할 수 있으며, 거부시 이용이 제한될 수 있습니다.
      <a class="pop_close" @click="closeTerms()">닫기</a>
    </div>
    <!-- //팝업 -->

  </div>
  <!-- //서브컨텐츠 -->
</template>

<script>
import DatePicker from 'vue3-datepicker';
import koLocale from 'date-fns/locale/ko';
import {QuillEditor} from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {format} from "date-fns";

export default {
  name: "QnaRegister",
  components: {
    DatePicker,
    QuillEditor
  },
  data() {
    return {
      timeList: [],
      title: '',
      name: '',
      phone: '',
      email: '',
      password: '',
      today: new Date(),
      selectedDate: new Date(),
      selectedTime: '',
      selectedTon: '5',
      chkTerms: false,

      locale: koLocale
    }
  },
  created() {
    this.setTimeList()
  },
  mounted() {
    document.title = '실시간 문의 :: 마국로지스'

    this.selectedTime = this.timeList[0].time
  },
  methods: {
    closedPicker() {
      this.$refs.select.focus()
    },
    openTerms() {
      window.$("#pop1").show();
      window.$("#mask").fadeIn(100);
    },
    closeTerms(){
      window.$(".pop_layer").hide();
      window.$("#mask").fadeOut(100);
    },
    dataClear() {
      // 데이터 초기화
      this.title = ''
      this.name = ''
      this.phone = ''
      this.email = ''
      this.password = ''
      this.content = this.$refs.quillEditor.setText('')
      this.selectedDate = new Date()
      this.selectedTime = ''
      this.selectedTon = '5'
      this.chkTerms = false
    },
    register() {
      if (this.title === '') {
        this.notify('error', '제목을 입력해주세요')
        return
      }

      if (this.name === '') {
        this.notify('error', '이름을 입력해주세요')
        return
      }

      if (this.phone === '') {
        this.notify('error', '연락처를 입력해주세요')
        return
      }

      if (this.email === '') {
        this.notify('error', '이메일을 입력해주세요')
        return
      }

      if (!this.validateEmail(this.email)) {
        this.notify('error', '이메일을 정확하게 입력해주세요')
        return
      }

      if (this.password === '') {
        this.notify('error', '문의 비밀번호를 입력해주세요')
        return
      }

      let content = this.$refs.quillEditor.getHTML()
      if (content === '<p><br></p>'){
          this.notify('error', '내용을 입력해주세요.')
          return
      }

      if (this.selectedDate === '') {
        this.notify('error', '상담가능날짜를 선택해주세요')
        return
      }

      if (this.chkTerms === false) {
        this.notify('error', '개인정보 수집 및 이용에 동의해주세요')
        return
      }

      let queryString = '?title=' + this.title + '&content=' + encodeURIComponent(this.$refs.quillEditor.getHTML()) + '&name=' + this.name + '&phone=' + this.phoneNumber(this.phone)
          + '&email=' + this.email + '&password=' + encodeURIComponent(this.password) + '&consultationAt=' + format(this.selectedDate, 'yyyy-MM-dd')
          + '&hopeTon=' + this.selectedTon

      if (this.selectedTime !== '') {
        queryString = queryString + '&consultationTime=' + this.selectedTime
      }

      this.$post(this.$QNA_REGISTER + queryString, this.$options.name + '_register', null, () => {
        this.notify('success', '문의 등록이 완료되었습니다.')
        this.dataClear()

      }, (result) => {
        this.httpError(result)
      })
    },
    setTimeList() {
      this.timeList = [
        {
          time: '',
          name: '시간선택',
        },
        {
          time: '00:00',
          name: '오전 12:00',
        },
        {
          time: '00:30',
          name: '오전 12:30',
        },
        {
          time: '01:00',
          name: '오전 01:00',
        },
        {
          time: '01:30',
          name: '오전 01:30',
        },
        {
          time: '02:00',
          name: '오전 02:00',
        },
        {
          time: '02:30',
          name: '오전 02:30',
        },
        {
          time: '03:00',
          name: '오전 03:00',
        },
        {
          time: '03:30',
          name: '오전 03:30',
        },
        {
          time: '04:00',
          name: '오전 04:00',
        },
        {
          time: '04:30',
          name: '오전 04:30',
        },
        {
          time: '05:00',
          name: '오전 05:00',
        },
        {
          time: '05:30',
          name: '오전 05:30',
        },
        {
          time: '06:00',
          name: '오전 06:00',
        },
        {
          time: '06:30',
          name: '오전 06:30',
        },
        {
          time: '07:00',
          name: '오전 07:00',
        },
        {
          time: '07:30',
          name: '오전 07:30',
        },
        {
          time: '08:00',
          name: '오전 08:00',
        },
        {
          time: '08:30',
          name: '오전 08:30',
        },
        {
          time: '09:00',
          name: '오전 09:00',
        },
        {
          time: '09:30',
          name: '오전 09:30',
        },
        {
          time: '10:00',
          name: '오전 10:00',
        },
        {
          time: '10:30',
          name: '오전 10:30',
        },
        {
          time: '11:00',
          name: '오전 11:00',
        },
        {
          time: '11:30',
          name: '오전 11:30',
        },
        {
          time: '12:00',
          name: '오후 12:00',
        },
        {
          time: '12:30',
          name: '오후 12:30',
        },
        {
          time: '13:00',
          name: '오후 01:00',
        },
        {
          time: '13:30',
          name: '오후 01:30',
        },
        {
          time: '14:00',
          name: '오후 02:00',
        },
        {
          time: '14:30',
          name: '오후 02:30',
        },
        {
          time: '15:00',
          name: '오후 03:00',
        },
        {
          time: '15:30',
          name: '오후 03:30',
        },
        {
          time: '16:00',
          name: '오후 04:00',
        },
        {
          time: '16:30',
          name: '오후 04:30',
        },
        {
          time: '17:00',
          name: '오후 05:00',
        },
        {
          time: '17:30',
          name: '오후 05:30',
        },
        {
          time: '18:00',
          name: '오후 06:00',
        },
        {
          time: '18:30',
          name: '오후 06:30',
        },
        {
          time: '19:00',
          name: '오후 07:00',
        },
        {
          time: '19:30',
          name: '오후 07:30',
        },
        {
          time: '20:00',
          name: '오후 08:00',
        },
        {
          time: '20:30',
          name: '오후 08:30',
        },
        {
          time: '21:00',
          name: '오후 09:00',
        },
        {
          time: '21:30',
          name: '오후 09:30',
        },
        {
          time: '22:00',
          name: '오후 10:00',
        },
        {
          time: '22:30',
          name: '오후 10:30',
        },
        {
          time: '23:00',
          name: '오후 11:00',
        },
        {
          time: '23:30',
          name: '오후 11:30',
        },
      ]
    }
  },
  watch: {
    phone() {
      if (!(/^[0-9]*$/.test(this.phone))) {
        this.phone = /([0-9]*)/.exec(this.phone)[0]
      }
    }
  }
}
</script>

<style scoped>

</style>
