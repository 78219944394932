export default Object.freeze({
    install(Vue) {
        Vue.config.globalProperties.numberWithCommas = function (number) {
            if (number.toString().split(".").length === 2) {
                let parts = number.toString().split(".")
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                parts[1] = parts[1].slice(0, 4)
                return parts.join('.')
            } else {
                return (number + '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
        }

        // 휴대폰 번호 형식
        Vue.config.globalProperties.phoneNumber = function (phone) {
            return phone.substr(0,3) + '-'+ phone.substr(3,4) +'-'+ phone.substr(7,4)
        }

        // 이메일 정규식 체크
        Vue.config.globalProperties.validateEmail = function (email) {
            if ((/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(email))) {
                return true
            }
            return false
        }

        Vue.config.globalProperties.orderStatus = function (status) {
            if (status === '0') {
                return '접수대기'
            } else if (status === '1') {
                return '입금완료'
            } else if (status === '2') {
                return '일부입금'
            } else if (status === '3') {
                return '처리불가'
            }
        }

        Vue.config.globalProperties.ticketStatus = function (status) {
            if (status === '0') {
                return '접수대기'
            } else if (status === '1') {
                return '입금완료'
            } else if (status === '2') {
                return '처리불가'
            }
        }
    }
})
