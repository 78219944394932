<template>
  <div id="app">
    <menu-bar :site-info="siteInfo"></menu-bar>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <bottom-footer :site-info="siteInfo"></bottom-footer>
    <div id="alert-wrap" class="alert-wrap"></div>
  </div>
</template>

<script>

import BottomFooter from "@/components/common/MGBottomFooter";
import MenuBar from "@/components/common/MGMenuBar";

export default {
  name: 'App',
  components: {
    MenuBar,
    BottomFooter
  },
  data() {
    return {
      siteInfo: null
    }
  },
  created() {
    this.emitter.on(this.$EVENT_SITE_INFO, () => {
      this.setSiteInfo()
    })
  },
  mounted() {
    if (localStorage.getItem('siteInfo')) {
      this.setSiteInfo()
    }
  },
  methods: {
    setSiteInfo() {
      this.siteInfo = JSON.parse(localStorage.getItem('siteInfo'))
      let favicon = window.document.getElementById('favicon')
      if (this.siteInfo.site_favicon) {
        favicon.href = this.$IMAGE_DOMAIN + this.siteInfo.site_favicon // favicon
      }
    },
  }
}
</script>

<style>
#app {
  height: 100%;
}

/*date-picker*/
.v3dp__popout {
  left: -120px;
}

/*editor*/
.ql-editor {
  padding: 0 !important;
}
</style>
