import axios from 'axios';

let requestOption = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        timeout: 60000
    }
}

let pending_get = {}
let pending_post = {}
let pending_put = {}
let pending_delete = {}

export default Object.freeze({
    install(Vue) {
        Vue.config.globalProperties.$DOMAIN = process.env.VUE_APP_DOMAIN
        Vue.config.globalProperties.$IMAGE_DOMAIN = process.env.VUE_APP_IMAGE_DOMAIN /*이미지 main domain*/

        Vue.config.globalProperties.$SITE_INFO = "/siteInfo" /*사이트 정보*/

        Vue.config.globalProperties.$PARTNER = "/partner" /*주요거래처 리스트*/

        Vue.config.globalProperties.$MANAGER = "/manager" /*물류 배차 담당자 리스트*/

        Vue.config.globalProperties.$QNA_LIST = "/qna" /*문의사항 가져오기*/
        Vue.config.globalProperties.$QNA_REGISTER = "/qna" /*문의사항 등록*/
        Vue.config.globalProperties.$QNA_DETAIL = "/qna/" /*문의사항 상세 :id*/
        Vue.config.globalProperties.$QNA_DELETE = "/qna/" /*문의사항 삭제 :id*/

        Vue.config.globalProperties.$post = function (callUrl, caller, postData, success, fail) {
            if (pending_post[arguments[0] + caller]) {
                console.log('request post fail : ' + arguments[0] + caller)
                return
            }
            pending_post[arguments[0] + caller] = true
            let _requestOption = requestOption()

            axios.post(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_post[arguments[0] + caller] = false

                if (response.status === 200 || response.status === 201 || response.status === 205){
                    success(response.data)
                } else {
                    fail(response.data)
                }

            }).catch((e) => {
                pending_post[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.config.globalProperties.$put = function (callUrl, caller, postData, success, fail) {
            if (pending_put[arguments[0] + caller]) {
                console.log('request put fail : ' + arguments[0] + caller)
                return
            }
            pending_put[arguments[0] + caller] = true
            let _requestOption = requestOption()

            axios.put(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_put[arguments[0] + caller] = false

                if (response.status === 205){
                    success(response.data)
                } else {
                    fail(response.data)
                }

            }).catch((e) => {
                pending_put[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.config.globalProperties.$delete = function (callUrl, caller, success, fail) {
            if (pending_delete[arguments[0] + caller]) {
                console.log('request delete fail : ' + arguments[0] + caller)
                return
            }
            pending_delete[arguments[0] + caller] = true
            let _requestOption = requestOption()

            axios.delete(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_delete[arguments[0] + caller] = false

                if (response.status === 204){
                    success(response.data)
                } else {
                    fail(response.data)
                }

            }).catch((e) => {
                pending_delete[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.config.globalProperties.$get = function (callUrl, caller, success, fail) {
            if (pending_get[arguments[0] + caller]) {
                console.log('request get fail : ' + arguments[0] + caller)
                return
            }
            pending_get[arguments[0] + caller] = true
            let _requestOption = requestOption()

            axios.get(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_get[arguments[0] + caller] = false

                if (response.status === 200){
                    success(response.data)
                } else {
                    fail(response.data)
                }

            }).catch(e => {
                pending_get[arguments[0] + caller] = false
                fail(e.response)
            })
        }
    }
})