<template>
  <!-- 메인컨텐츠 -->
  <div id="contents">

    <!-- 상단 -->
    <section class="quick">
      <div class="q1">
        <a @click="$router.push({name: 'Introduction01'})">
          <div class="txt">
            <em></em>
            <h2>마국로지스</h2>
            <p>신뢰와 정직을 바탕으로 <br />성장과 발전해 나가겠습니다</p>
          </div>
        </a>
      </div>
      <div class="q2">
        <a @click="$router.push({name: 'BusinessPartner'})">
          <div class="txt">
            <em></em>
            <h2>고객사현황</h2>
            <p>마국로지스와 함께 해주시는 <br />소중한 고객사들입니다</p>
          </div>
        </a>
      </div>
      <div class="q3">
        <a @click="$router.push({name: 'ManagerInformation'})">
          <div class="txt">
            <em></em>
            <h2>물류담당팀</h2>
            <p>마국로지스의 <br />물류전담자들을 소개합니다</p>
          </div>
        </a>
      </div>
      <div class="q4">
        <a @click="$router.push({name: 'QnaRegister'})">
          <div class="txt">
            <em></em>
            <h2>실시간문의</h2>
            <p>문의를 남겨주시면 <br />빠르게 답변드리겠습니다</p>
          </div>
        </a>
      </div>
    </section>
    <!-- //상단 -->

    <!-- 하단 -->
    <section class="intro">
      <p>
        <strong>(주)마국로지스</strong>는 정식 화물자동차운송주선사업허가를 받고, <br />
        <b>자본금이 탄탄하여 안전한 화물주선전문 기업</b>입니다.
      </p>
      <img src="/images/intro_con.png" alt="" />
    </section>
    <!-- //하단 -->

  </div>
  <!-- //메인컨텐츠 -->
</template>

<script>
export default {
  name: 'Main',
  components: {},
  data() {
    return {}
  },
  created() {
    this.getSiteInfo()
  },
  mounted() {
    document.title = '마국로지스'
  },
  methods: {
    getSiteInfo() {
      this.$get(this.$SITE_INFO, this.$options.name + '_get_site_info', (result) => {
        this.siteInfo = result.data
        localStorage.setItem('siteInfo', JSON.stringify(this.siteInfo))
        this.emitter.emit(this.$EVENT_SITE_INFO)

      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style scoped>

</style>
