<template>
  <!-- 서브컨텐츠 -->
  <div id="container" class="p04">

    <!-- 비주얼 -->
    <section class="sub_v">
      <h2>실시간 문의</h2>
    </section>
    <!-- //비주얼 -->

    <!-- 탭 -->
    <div class="sub_tab">
      <ul>
        <li><a @click="$router.push({name: 'QnaRegister'})">실시간 문의하기</a></li>
        <li class="on"><a>나의 문의내역</a></li>
      </ul>
    </div>
    <!-- //탭 -->

    <section class="con">

      <div class="form write">

        <div class="wrap">
          <dl>
            <dt>제목</dt>
            <dd><span>{{ title }}</span></dd>
          </dl>
          <dl>
            <dt>이름</dt>
            <dd><span>{{ name }}</span></dd>
          </dl>
          <dl>
            <dt>연락처</dt>
            <dd><span>{{ phone }}</span></dd>
          </dl>
          <dl>
            <dt>이메일</dt>
            <dd><span>{{ email }}</span></dd>
          </dl>
          <dl>
            <dt>유선상담 가능 날짜</dt>
            <dd>
              <span>{{ date }}</span>
            </dd>
          </dl>
          <dl>
            <dt>희망톤수</dt>
            <dd>
              <span>{{ ton === '0' ? '기타' : ton + '톤' }}</span>
            </dd>
          </dl>
          <dl>
            <dt>간략 문의내용</dt>
            <dd>
              <span class="content" v-html="content"></span>
            </dd>
          </dl>
          <dl v-if="answer !== null">
            <dt>문의 답변내용</dt>
            <dd>
              <span class="content" v-html="answer"></span>
            </dd>
          </dl>
        </div>

        <div class="btns">
          <a @click="$router.go(-2)">목록으로 이동</a>
        </div>
      </div>

    </section>
  </div>
  <!-- //서브컨텐츠 -->
</template>

<script>
export default {
  name: "QnaDetail",
  data() {
    return {
      id: 0,
      password: '',
      title: '',
      name: '',
      phone: '',
      email: '',
      date: '',
      ton: '',
      content: '',
      answer: ''
    }
  },
  mounted() {
    document.title = '실시간 문의 :: 마국로지스'
    this.scrollTop()

    this.id = this.$route.query.id
    this.password = this.$route.query.password

    this.getDetail()
  },
  methods: {
    getDetail() {
      this.$post(this.$QNA_DETAIL + this.id + '?password=' + this.password, this.$options.name + '_get_detail', null, (result) => {
        this.title = result.data.title
        this.name = result.data.name
        this.phone = result.data.phone
        this.email = result.data.email
        this.date = result.data.consultation_at
        if (result.data.consultation_time) {
          this.date = this.date + ' ' + result.data.consultation_time
        }
        this.ton = result.data.hope_ton
        this.content = result.data.content
        this.answer = result.data.reply

      }, (result) => {
        this.httpError(result)

        this.$router.back()
      })
    },
  }
}
</script>

<style scoped>

</style>