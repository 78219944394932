<template>
  <!-- 서브컨텐츠 -->
  <div id="container" class="p01 p01_1">

    <!-- 비주얼 -->
    <section class="sub_v">
      <h2>개요</h2>
    </section>
    <!-- //비주얼 -->

    <!-- 탭 -->
    <div class="sub_tab">
      <ul>
        <li class="on"><a>개요</a></li>
        <li><a @click="$router.push({ name : 'Introduction02' })">경영이념</a></li>
        <li><a @click="$router.push({ name : 'Introduction03' })">진행사업</a></li>
      </ul>
    </div>
    <!-- //탭 -->

    <section class="con">
      <img src="/images/intro_cup.png" alt="">
      <div class="txt">
        <h4>마국로지스는</h4>
        <p>운송업의 <b>혁신</b>을 위해 앞장섭니다.<br />
          수많은 회사 중 <b>마국로지스</b>를 선택해야하는 이유!<br />
          <b>신속, 정확</b>한 물류운송과 <b>합리적</b>인 비용으로 보여드리겠습니다.<br />
          신뢰를 바탕으로 고객 만족을 최우선시 합니다.
        </p>
      </div>
    </section>

  </div>
  <!-- //서브컨텐츠 -->
</template>

<script>
export default {
  name: "Introduction01",
  mounted() {
    document.title = '개요 :: 마국로지스'
  },
}
</script>

<style scoped>

</style>