<template>
  <!-- 서브컨텐츠 -->
  <div id="container" class="p03">

    <!-- 비주얼 -->
    <section class="sub_v">
      <h2>물류담당</h2>
    </section>
    <!-- //비주얼 -->

    <section class="con">
      <!-- 반복 -->
      <div class="box" v-for="(manager, index) in managerList" :key="index">
        <div class="pic"
             :style="{ background: 'url(' + $IMAGE_DOMAIN + manager.thumbnail_img + ') no-repeat center center/cover'}">
          <a v-show="manager.detail_img.length > 0" class="btn_pop1" @click="open(index)">크게보기</a>
        </div>
        <h3><strong>{{ manager.name }}</strong> {{ manager.position }}</h3>
        <ul>
          <li v-if="manager.tel !== ''"><em>Tel.</em><span>{{ manager.tel }}</span></li>
          <li><em>Ph.</em><span>{{ manager.phone }}</span></li>
        </ul>
      </div>
      <!-- //반복 -->
    </section>

    <!-- 이미지 팝업 -->
    <div id="mask" @click="close()"></div>
    <div id="pop1" class="pop_layer">
      <!--      <img :src="$IMAGE_DOMAIN + popupImgList[0]" alt="팝업이미지"/>-->
      <ul class="bxSlider">
        <li v-for="(img, index) in popupImgList" :key="index">
          <img :src="$IMAGE_DOMAIN + img" alt="">
        </li>
      </ul>
      <a class="pop_close z-50" @click="close()">닫기</a>
    </div>
    <!-- //팝업 -->

  </div>
  <!-- //서브컨텐츠 -->
</template>

<script>
export default {
  name: "ManagerInformation",
  data() {
    return {
      show: false,
      popupImgList: '',
      managerList: [],
    }
  },
  mounted() {
    document.title = '물류담당안내 :: 마국로지스'

    this.getManagerList()
  },
  methods: {
    getManagerList() {
      this.$get(this.$MANAGER, this.$options.name + '_get_manager_list', (result) => {
        this.managerList = result.data

      }, (result) => {
        this.httpError(result)
      })
    },
    open(_idx) {
      this.popupImgList = this.managerList[_idx].detail_img

      window.$("#pop1").show();
      window.$("#mask").fadeIn(100);

      if (this.popupImgList.length > 1) {
        setTimeout(() => {
          window.$(".bxSlider").bxSlider({
            auto: true,
            speed: 1000,
            pause: 2000, // 이미지 보여지는 시간(기본 4000)
            mode: 'horizontal', // fade, horizontal, vertical
            pager: false,
            controls: false,
          });
        }, 100)
      }
    },
    close() {
      window.$(".pop_layer").hide();
      window.$("#mask").fadeOut(100);
    }
  }
}
</script>

<style scoped>

</style>