<template>
  <!-- 서브컨텐츠 -->
  <div id="container" class="p01 p01_2">

    <!-- 비주얼 -->
    <section class="sub_v">
      <h2>진행사업</h2>
    </section>
    <!-- //비주얼 -->

    <!-- 탭 -->
    <div class="sub_tab">
      <ul>
        <li><a @click="$router.push({ name : 'Introduction01' })">개요</a></li>
        <li><a @click="$router.push({ name : 'Introduction02' })">경영이념</a></li>
        <li class="on"><a>진행사업</a></li>
      </ul>
    </div>
    <!-- //탭 -->

    <section class="con">

      <div>
        <h3><span>화물운송주선</span></h3>
        <p>물류 전담팀을 구성하여 수많은 고객사와의 협업을 진행중입니다.</p>
        <img class="pc" src="/images/intro2.png" alt="" />
        <img class="mo" src="/images/steps_mo.png" alt="" />
      </div>

      <div>
        <h3><span>화물운송업</span></h3>
        <p>운송허가를 획득 후 사업을 확장하였습니다.</p>
        <img class="pc" src="/images/intro2-1.png" alt="" />
        <img class="mo" src="/images/steps_mo-1.png" alt="" />
      </div>

    </section>

  </div>
  <!-- //서브컨텐츠 -->
</template>

<script>
export default {
  name: "Introduction03",
  mounted() {
    document.title = '진행사업 :: 마국로지스'
  },
}
</script>

<style scoped>

</style>