import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'

import api from "@/commonjs/api";
import common from '@/commonjs/common';
import filter from '@/commonjs/filter';
import mitt from "mitt";

const app = createApp(App)

app.use(router)
app.use(api)
app.use(common)
app.use(filter)

let emitter = mitt()
app.config.globalProperties.emitter = emitter

app.mount('#app')

