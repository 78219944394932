<template>
  <div>
    <!-- 헤더 -->
    <header>
      <h1 v-if="siteInfo"><a @click="goPage('Main')" :style="{ background: 'url(' + $IMAGE_DOMAIN + siteInfo.site_logo + ') no-repeat center center/contain'}">주식회사 마국로지스</a></h1>

      <nav>
        <ul>
          <li>
            <a>회사소개</a>
            <ul class="dep2">
              <li>
                <a @click="goPage('Introduction01')">개요</a>
              </li>
              <li>
                <a @click="goPage('Introduction02')">경영이념</a>
              </li>
              <li>
                <a @click="goPage('Introduction03')">진행사업</a>
              </li>
            </ul>
          </li>
          <li>
            <a @click="goPage('BusinessPartner')">주요거래처</a>
          </li>
          <li>
            <a @click="goPage('ManagerInformation')">물류담당</a>
          </li>
          <li>
            <a @click="goPage('QnaRegister')">실시간문의</a>
          </li>
        </ul>
      </nav>
      <a class="btn_m_menu" :class="{'active' : showMobileMenu}" @click="this.showMobileMenu = !this.showMobileMenu">
        <div></div>
        <div></div>
        <div></div>
      </a>
    </header>
    <!-- //헤더 -->

    <!-- 모바일메뉴 -->
    <div id="m_menu" :class="{'active' : showMobileMenu}">
      <ul>
        <li><a @click="goPage('Introduction01')">회사소개</a>
          <ul class="dep2">
            <li><a @click="goPage('Introduction01')">개요</a></li>
            <li><a @click="goPage('Introduction02')">경영이념</a></li>
            <li><a @click="goPage('Introduction03')">진행사업</a></li>
          </ul>
        </li>
        <li><a @click="goPage('BusinessPartner')">주요거래처</a></li>
        <li><a @click="goPage('ManagerInformation')">물류담당</a></li>
        <li><a @click="goPage('QnaRegister')">실시간문의</a></li>
      </ul>
    </div>
    <!-- //모바일메뉴 -->
  </div>
</template>

<script>
export default {
  name: "MGMenuBar",
  props: ['siteInfo'],
  data() {
    return {
      showMobileMenu: false,
    }
  },
  methods: {
    goPage(_name) {
      if (_name === this.$route.name) {
        this.$router.go(0)
      } else {
        this.$router.push({name: _name})
      }

      if (this.showMobileMenu) {
        this.showMobileMenu = false
      }
    }
  },
  watch:{
    showMobileMenu(){
      if (this.showMobileMenu){
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    },
  }
}
</script>

<style scoped>

</style>